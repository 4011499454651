<template >
  <div id="chart">
    <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  name: "donut-chart",
  data() {
    return {
      series: [44, 55, 41, 17],
      chartOptions: {
        legend: {
          show: true,
          fontSize: '14px',
        },
        colors: [
          '#3F98FF',
          '#33FEFF',
          '#102640',
          '#4033FF',
        ],
        dataLabels: {
          enabled: false,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                },
                value: {
                  fontSize: '1rem',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}₺`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  fontWeight:"600",
                  label: 'Aylık Gelir',
                  formatter(val) {
                    let vals = 0
                    val.globals.seriesTotals.forEach(element =>{
                      vals = vals + element
                      
                    })
                    
                    return `${parseInt(vals)}₺`
                  },
                },
              },
            },
          },
        },
        labels: ['1.Hafta', '2.Hafta', '3.Hafta', '4.Hafta'],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],

      },

    };
  },
}
</script>
<style lang="">
    
</style>